






















































































































































import {
	Vue,
	Component,
	Ref,
	PropSync,
	Watch,
} from 'vue-property-decorator'
import { FiltroDeMovimentacaoDeEstoque, ListagemDeMovimentacaoDeEstoque, ListagemDeMovimentacaoSintetica, Loja, Produto } from '@/models'
import { FindConfiguracaoDaContaUseCase, FindEstoqueUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import moment from 'moment'
import axios, { CancelTokenSource } from 'axios'
import { DataOptions } from 'vuetify';

@Component({
	components: {
		DataTableDeCrud,
		RangeDatePicker,
	},
})

export default class DialogoDeMovimentacaoDeEstoque extends Vue {
	@Ref() form!: HTMLFormElement
	@PropSync('value', { type: [Object, String], default: null })
	searchInput!: string | null
	
	cancelTokenEstoque: CancelTokenSource | null = null
	
	loja!: Loja;
	produto!: Produto | null

	obrigatorio = obrigatorio
	mostra = false
	buscandoAnalitica = false
	buscandoSintetica = false
	result: number | null = null
	errors: string[] = []

	paginacaoAnalitica: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	paginacaoSintetica: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	filtro = {
		lojaId: this. loja ? this.loja.id : null,
		skuOuEan: null,
		datas: [],
		horas: [],
	}

	totalDeElementosAnaliticos = -1
	totalDeElementosSinteticos = -1

	listagemDeMovimentacaoAnalitica: ListagemDeMovimentacaoDeEstoque[] = []

	listagemDeMovimentacaoSintetica: ListagemDeMovimentacaoSintetica[] = []

	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()

	findEstoqueUseCase = new FindEstoqueUseCase()

	tabs = [ 'Movimentação Analítica', 'Movimentação Sintética']
	
	tab = 0

	exportandoParaCsv = false

	created() {
		this.buscarConfiguracao()
	}

	async mostrar( loja: Loja) {
		this.loja = loja
		this.mostra = true
	}

	esconder() {
		const paginacaoDefault: DataOptions = {
			page: 0,
			itemsPerPage: 5,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}
		this.listagemDeMovimentacaoAnalitica = []
		this.listagemDeMovimentacaoSintetica = []
		this.filtro.skuOuEan = null
		this.filtro.datas = []
		this.paginacaoAnalitica = paginacaoDefault
		this.paginacaoSintetica = paginacaoDefault
		this.mostra = false
	}

	headerMovimentacao = [
		{ text: 'Data', value: 'createdAt'},
		{ text: 'Estoque Anterior', value: 'quantidadeAnterior'},
		{ text: 'Estoque Atual', value: 'quantidadeAtual'},
		{ text: 'Empenhado Atual', value: 'empenhadoAtual'},
		{ text: 'Estoque Disponível', value: 'disponivel'},
		{ text: 'Informação Adicional', value: 'infoAdicional', width: '135px'},
		{ text: 'Tipo', value: 'tipo'},
		{ text: 'Operador', value: 'vendedor'},
	]

	headerMovimentacaoSintetica = [
		{ text: 'Data', value: 'updatedAt'},
		{ text: 'Reserva', value: 'reserva'},
		{ text: 'Quantidade', value: 'quantidade'},
		{ text: 'Estoque Atual', value: 'estoque' },
		{ text: 'Operação', value: 'tipo' },
		{ text: 'Identificador', value: 'info_adicional'},
		{ text: 'Informação Adicional', value: 'observacao'},
	]

	buscarMovimentacoes() {
		this.paginacaoAnalitica = {
			page: 0,
			itemsPerPage: 5,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}
		this.paginacaoSintetica = {
			page: 0,
			itemsPerPage: 5,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}
		this.totalDeElementosAnaliticos = -1
		this.totalDeElementosSinteticos = -1
		this.buscarMovimentacaoAnalitica()
		this.buscarMovimentacaoSintetica()
	}

	async buscarMovimentacaoAnalitica() {
		if (!this.filtro.skuOuEan) return
		if (!this.loja) {
			AlertModule.setError('Selecione uma loja!')
			return
		}

		try {
			this.errors = []
			this.buscandoAnalitica = true
			if(!this.loja){
				return
			}
			
			this.filtro.lojaId = this.loja.id
			
			if (this.datasEstaoInvertidas()) {
				this.filtro.datas.reverse()
			}

			const dataHoraInicial = this.gerarDataHora(
				this.filtro.datas[0],
				this.filtro.horas[0] || '00:00',
			) || undefined
			
			const dataHoraFinal = this.gerarDataHora(
				this.filtro.datas[1],
				this.filtro.horas[1] || '23:59',
			) || undefined

			const filtrosFormatados: FiltroDeMovimentacaoDeEstoque = {
				lojaId: this.filtro.lojaId ? this.filtro.lojaId : '',
				skuOuEan: this.filtro.skuOuEan ? this.filtro.skuOuEan : '',
				dataInicial: dataHoraInicial,
				dataFinal: dataHoraFinal,
				page: this.paginacaoAnalitica.page ? this.paginacaoAnalitica.page - 1 : 0,
				size: this.paginacaoAnalitica.itemsPerPage,
			}
			
			if (this.cancelTokenEstoque) this.cancelTokenEstoque.cancel()
			this.cancelTokenEstoque = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelTokenEstoque.token,
			}

			const pageAnalitica = await this.findEstoqueUseCase.findMovimentacaoAnalitica(
				filtrosFormatados,
				axiosConfig,
			)

			this.listagemDeMovimentacaoAnalitica = pageAnalitica.content
			this.totalDeElementosAnaliticos = pageAnalitica.totalElements
		} catch (error) {
			this.errors = error.response.data
		} finally {
			this.buscandoAnalitica = false
		}
	}

	async buscarMovimentacaoSintetica() {
		if (!this.filtro.skuOuEan) return
		if (!this.loja) {
			AlertModule.setError('Selecione uma loja!')
			return
		}

		try {
			this.errors = []
			this.buscandoSintetica = true
			if(!this.loja){
				return
			}
			
			this.filtro.lojaId = this.loja.id
			
			if (this.datasEstaoInvertidas()) {
				this.filtro.datas.reverse()
			}
			

			const dataHoraInicial = this.gerarDataHora(
				this.filtro.datas[0],
				this.filtro.horas[0] || '00:00',
			) || undefined
			
			const dataHoraFinal = this.gerarDataHora(
				this.filtro.datas[1],
				this.filtro.horas[1] || '23:59',
			) || undefined

			const filtrosFormatadosSinteticos: FiltroDeMovimentacaoDeEstoque = {
				lojaId: this.filtro.lojaId ? this.filtro.lojaId : '',
				skuOuEan: this.filtro.skuOuEan ? this.filtro.skuOuEan : '',
				dataInicial: dataHoraInicial,
				dataFinal: dataHoraFinal,
				page: this.paginacaoSintetica.page ? this.paginacaoSintetica.page - 1 : 0,
				size: this.paginacaoSintetica.itemsPerPage,
			}
			
			if (this.cancelTokenEstoque) this.cancelTokenEstoque.cancel()
			this.cancelTokenEstoque = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelTokenEstoque.token,
			}
			const pageSintetica = await this.findEstoqueUseCase.findMovimentacaoSintetica(
				filtrosFormatadosSinteticos,
				axiosConfig,
			)
			
			this.listagemDeMovimentacaoSintetica = pageSintetica.content
			
			this.totalDeElementosSinteticos = pageSintetica.totalElements
			
		} catch (error) {
			this.errors = error.response.data
		} finally {
			this.buscandoSintetica = false
		}
	}

	async exportarCsv() {
		if (!this.filtro.skuOuEan) return
		if (!this.loja) {
			AlertModule.setError('Selecione uma loja!')
			return
		}

		try {
			this.errors = []
			this.buscandoAnalitica = true
			this.exportandoParaCsv = true
			if(!this.loja){
				return
			}
			
			this.filtro.lojaId = this.loja.id
			
			if (this.datasEstaoInvertidas()) {
				this.filtro.datas.reverse()
			}

			const dataHoraInicial = this.gerarDataHora(
				this.filtro.datas[0],
				this.filtro.horas[0] || '00:00',
			) || undefined
			
			const dataHoraFinal = this.gerarDataHora(
				this.filtro.datas[1],
				this.filtro.horas[1] || '23:59',
			) || undefined

			const filtrosFormatados: FiltroDeMovimentacaoDeEstoque = {
				lojaId: this.filtro.lojaId ? this.filtro.lojaId : '',
				skuOuEan: this.filtro.skuOuEan ? this.filtro.skuOuEan : '',
				dataInicial: dataHoraInicial,
				dataFinal: dataHoraFinal,
				page: this.paginacaoAnalitica.page ? this.paginacaoAnalitica.page - 1 : 0,
				size: this.paginacaoAnalitica.itemsPerPage,
			}
			
			if (this.cancelTokenEstoque) this.cancelTokenEstoque.cancel()
			this.cancelTokenEstoque = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelTokenEstoque.token,
			}

			const response = await this.findEstoqueUseCase.findMovimentacaoAnaliticaCompleta(
				filtrosFormatados,
				axiosConfig,
			)

			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a')

			const dataAtual = new Date()

			const dataFormatada = dataAtual.toLocaleDateString().replaceAll('-', '_')
			const horaFormatada = dataAtual.toLocaleTimeString().replaceAll(':', '_')

			link.href = url
			link.setAttribute('download', `historico_estoque_${dataFormatada + '_' + horaFormatada}.csv`)
			document.body.appendChild(link)
			link.click()
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.buscandoAnalitica = false
			this.exportandoParaCsv = false
		}
	}

	async buscarConfiguracao() {
		try {
			const configuracaoDaConta =
				await this.findConfiguracaoDaContaUseCase.find()
			this.result =
				configuracaoDaConta?.eans + configuracaoDaConta?.medida || 13
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	get itensFormatadosMovimentacaoAnalitica(){
		return this.listagemDeMovimentacaoAnalitica.map(movimentacao => ({
			...movimentacao,
			createdAt: dateToPtBrFormat(movimentacao.createdAt),
			disponivel: (movimentacao.quantidadeAtual - movimentacao.empenhadoAtual),
		}))
	}

	get itensFormatadosMovimetacaoSintetica(){
		return this.listagemDeMovimentacaoSintetica.map(movimentacao => ({
			...movimentacao,
			updatedAt: dateToPtBrFormat(movimentacao.updatedAt),
		}))
	}

	datasEstaoInvertidas() {
		if (!this.filtro.datas[0] || !this.filtro.datas[1]) return false
		if (this.filtro.datas[1] < this.filtro.datas[0]) return true
	}

	gerarDataHora(
		data?: string | null,
		hora?: string | null,
	): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	@Watch('paginacaoAnalitica', {deep: true})
	onChangePaginacao() {
		this.buscarMovimentacaoAnalitica()
	}

	@Watch('paginacaoSintetica', {deep: true})
	onChangePaginacaoSintetica() {
		this.buscarMovimentacaoSintetica()
	}

	irPara(infoAdicional: string) {
		const partes = infoAdicional.split(' ');
		const identificador = partes[0];

		const filtro = {
			busca: '',
			lojaId: '',
			datas: [null, null],
			horas: [null, null],
			cliente: null,
			pdvId: '',
			numeroDaNota: '',
			serieFiscal: '',
			exibeVendasComErros: false,
			ambientes: ['Homologação', 'Produção'],
			identificador: identificador,
			identificadorExterno: '',
			vendasComOrcamento: false,
			vendasComPagamentoCancelado: false,
			origem: null,
		};

		localStorage.setItem('FILTRO_DE_VENDAS', JSON.stringify(filtro));
		window.open(`/vendas`, '_blank');
	}

}
