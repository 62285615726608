































































































































































































































































import {  AbrirCaixaUseCase, FindEstoqueUseCase, ImportaEstoquesUseCase } from '@/usecases';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator' ;
import { VueCsvImport } from 'vue-csv-import';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { EstoqueCsvForm, EstoqueGenerico, Loja } from '@/models';
import { ExportAllItensEstoqueUseCase } from '@/usecases/loja/adapter/ExportAllItensEstoqueUseCase';
import DatePicker from '@/components/ui/DatePicker.vue';
import RangerTimePicker from '@/components/ui/RangerTimePicker.vue';
import Confirmacao from '@/components/ui/Confirmacao.vue';
import DialogoDeSelecaoDeBalanco from './DialogoDeSelecaoDeBalanco.vue';
import { Romaneio } from '@/models/Romaneio';
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils';
import { RomaneioEstoqueUseCase } from '@/usecases/loja/RomaneioEstoqueUseCase';
import DialogoDeComparacaoDeEstoque from './DialogoDeComparacaoDeEstoque.vue';

@Component({
	components: {
		VueCsvImport,
		DatePicker,
		RangerTimePicker,
		Confirmacao,
		DialogoDeSelecaoDeBalanco,
		DialogoDeComparacaoDeEstoque,
	},
})
export default class DialogoDeCsvTabelaDeEstoque extends Vue {
	@Ref() csvComponent!: VueCsvImport
	@Ref() confirmacao!: Confirmacao
	@Ref() dialogoDeSelecaoDeBalanco!: DialogoDeSelecaoDeBalanco
	@Ref() dialogoDeComparacaoDeEstoque!: DialogoDeComparacaoDeEstoque
	@Prop() loja!: Loja
	@Prop() depositoId!: string

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	mostra = false
	tab: null | number = null
	tabs = [
		{ action: 'Importar', icon: 'mdi-file-upload' },
		{ action:'Exportar', icon: 'mdi-file-download' },
	]
	exportAllItensEstoqueUseCase = new ExportAllItensEstoqueUseCase()
	importaEstoquesUseCase = new ImportaEstoquesUseCase()
	abrirCaixaUseCase = new AbrirCaixaUseCase()
	romaneioEstoqueUseCase = new RomaneioEstoqueUseCase()
	findEstoqueUseCase = new FindEstoqueUseCase()
	importando = false
	exportando = false
	estadoDaSelecao = "Nenhum arquivo selecionado"  
	csv: EstoqueCsvForm[] | null = null
	opcaoA = 'Local'
	opcaoB = 'Sistema'

	camposCsvComEstoqueMinimo = {
		sku:'skuDoProduto', 
		qtdEstoque: 'qtdeEstoque',
		estoqueMinimo: 'estoqueMinimo',
	}

	camposCsvSemEstoqueMinimo = {
		sku:'skuDoProduto', 
		qtdEstoque: 'qtdeEstoque',
	}
		
	arquivo = 'File'

	tabelaNome: string | null = null
	tabelaId: string | null = null
	selecionado: boolean | null = null
	inputFile: HTMLInputElement | null = null
	isRetroativo: boolean | null = null
	isParcial = false
	dataDoInventario = ''
	time: any = null
	menu2 = false
	
	hover= false

	tipoLayout: string | null = null
	romaneioSelecionado: Romaneio | null = null

	carregandoComparacao = false

	async mostrar(tabelaId?: string, tabelaNomeRecebido?: string){
		this.tabelaId = tabelaId ? tabelaId : null
		this.tabelaNome = tabelaNomeRecebido ? tabelaNomeRecebido : null
		this.mostra = true
	}
	cancelar() {
		this.importando = false
		this.csv = null 
		this.mostra = false
		this.csvComponent.sample =  null
		this.csvComponent.fileSelected = false
		this.csvComponent.map = []
		if(this.inputFile!= null)
			this.inputFile.value = ''

		this.romaneioSelecionado = null
	}

	async concluirImportacao(){

		if (!this.tabelaId) return
		try{

			const notasInvalidasDosPdvs: Set<string> = new Set();

			for (let i = 0; i < this.loja.pdvs.length; i++) {
				const notasInvalidas = await this.abrirCaixaUseCase.podeAbrirCaixa(this.loja.pdvs[i].id);
				
				if (notasInvalidas) {
					notasInvalidas.forEach(nota => notasInvalidasDosPdvs.add(nota));
				}
			}

			if (notasInvalidasDosPdvs.size > 0 && this.loja.configuracaoDaLoja.permiteInventarioComNotasInvalidas === false) {
				const listaNotas = Array.from(notasInvalidasDosPdvs).join(', ');
				AlertModule.setError("Sem permissão para realizar a importação com notas invalidas, é necessario corrigir as notas das seguintes vendas para continuar: " + listaNotas);
				return;
			}

			if (this.romaneioSelecionado) {
				this.romaneioSelecionado.itens.forEach(item => {

					if (this.csv) {
						this.csv.push({
							sku: item.produto.sku,
							qtdEstoque: item.quantidade,
							estoqueMinimo: 0,
						} as EstoqueCsvForm)
					} else {
						this.csv = [
							{
								sku: item.produto.sku,
								qtdEstoque: item.quantidade,
								estoqueMinimo: 0,
							} as EstoqueCsvForm,
						]
					}
				})
			}

			if(this.csv) {
				if(!this.isRetroativo) {
					const inventarioRetroativo = {
						isRetroativo: false,
						horaInventario: null,
						dataInventario: null,
						isParcial: this.isParcial,
					};
					await this.importaEstoquesUseCase.execute(this.tabelaId,{ 
						csv: this.csv, 
						retroativo: inventarioRetroativo,
					})
				} else {
					const inventarioRetroativo = {
						isRetroativo: this.isRetroativo,
						horaInventario: this.time,
						dataInventario: this.dataDoInventario,
						isParcial: this.isParcial,
					};
					await this.importaEstoquesUseCase.execute(this.tabelaId, { 
						csv: this.csv, 
						retroativo: inventarioRetroativo,
					});
				}
			}

			if (this.romaneioSelecionado) {
				await this.romaneioEstoqueUseCase.atualizar({
					id: this.romaneioSelecionado.id,
					sequenciaNumerica: 0,
					tipoMovimentacaoRomaneio: this.romaneioSelecionado.tipoMovimentacaoRomaneio,
					usuarioCrio: '',
					usuarioConfirmou: '',
					statusRomaneio: 'Confirmado',
					deposito: this.romaneioSelecionado.deposito.id || '',
					dataHoraCriacao: this.romaneioSelecionado.dataHoraCriacao,
					itens: [],
					depositoDestino: this.romaneioSelecionado.depositoDestino.id || '',
					observacaoRomaneio: this.romaneioSelecionado.observacaoRomaneio,
					zerarTodoEstoque: this.romaneioSelecionado.zerarTodoEstoque,
				})
			}

			AlertModule.setSuccess('Itens em processamento')
			this.$emit('concluiuImportacao')
			this.importando = false
			this.csv = null 
			this.mostra = false
			this.csvComponent.sample =  null
			this.csvComponent.fileSelected = false
			this.csvComponent.map = []
			if(this.inputFile!= null){
				this.inputFile.value = ''
			}
			this.romaneioSelecionado = null
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	async exportarCsv() {
		if(!this.tabelaId) return
		if(!this.tipoLayout) return
		try {
			AlertModule.setSuccess('O arquivo está sendo gerado. O processo pode levar alguns minutos.')
			const response: any = await this.exportAllItensEstoqueUseCase.exportEstoque(this.tabelaId, this.tipoLayout)
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `Estoque_${this.tabelaNome}.csv`)
			document.body.appendChild(link)
			link.click()
		} catch (e) {
			AlertModule.setError(e)
		}
	}
	clickCsv() {
		this.romaneioSelecionado = null
		this.inputFile = document.querySelector('.form-control-file')
		if(this.inputFile != null)
			this.inputFile.click();
		
	}

	escolherOpcao() {
		this.confirmacao.mostrar()
	}

	exibirSeletorDeBalanco() {
		this.dialogoDeSelecaoDeBalanco.mostrar(this.depositoId)
	}

	get arquivoSelecionado() {
		if((this.csvComponent && this.csvComponent.fileSelected) || (this.inputFile && this.inputFile.value)){
			return "Arquivo selecionado, favor definir colunas"
		}
		return "Nenhum arquivo selecionado"
	}

	confirmarRomaneio(romaneioSelecionado: Romaneio) {
		this.inputFile = null
		this.csv = null
		const dataHoraFormatada = new Date(Date.parse(romaneioSelecionado.dataHoraCriacao.toString()))
		this.time = `${dataHoraFormatada.getHours().toString().padStart(2, '0')}:${dataHoraFormatada.getMinutes().toString().padStart(2, '0')}:${dataHoraFormatada.getSeconds().toString().padStart(2, '0')}`
		this.dataDoInventario = `${dataHoraFormatada.getFullYear()}-${(dataHoraFormatada.getMonth()+1).toString().padStart(2, '0')}-${dataHoraFormatada.getDate().toString().padStart(2, '0')}`
		this.romaneioSelecionado = romaneioSelecionado
	}

	async abrirDialogoComparacao() {
		try {
			this.carregandoComparacao = true

			const estoques = (await this.findEstoqueUseCase.findAllByDeposito(this.depositoId)).map(estoque => ({
				nome: estoque.nomeCompletoDoProduto,
				quantidade: estoque.qtdeEstoque - estoque.qtdeEmpenhada,
				sku: estoque.skuDoProduto,
			} as EstoqueGenerico))

			if (this.romaneioSelecionado) {
				this.romaneioSelecionado.itens.forEach(item => {

					if (this.csv) {
						this.csv.push({
							sku: item.produto.sku,
							qtdEstoque: item.quantidade,
							estoqueMinimo: 0,
						} as EstoqueCsvForm)
					} else {
						this.csv = [
							{
								sku: item.produto.sku,
								qtdEstoque: item.quantidade,
								estoqueMinimo: 0,
							} as EstoqueCsvForm,
						]
					}
				})
			}

			const listaArquivo = this.csv ? this.csv.map(estoque => ({
				nome: null,
				quantidade: estoque.qtdEstoque,
				sku: estoque.sku,
			} as EstoqueGenerico)) : []


			this.dialogoDeComparacaoDeEstoque.mostrar(estoques, listaArquivo, this.isParcial)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.carregandoComparacao = false
		}
	}

}
