import store from '@/store/vuex'
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import { Deposito } from '@/models'

@Module({
	name: 'DepositoStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class DepositoStore extends VuexModule {
	depositosCarregados: Deposito[] = []
	depositoSelecionado: Deposito | null = null

	@Mutation
	setDepositos(depositos: Deposito[]) {
		this.depositosCarregados = depositos
	}

	@Mutation
	setDepositoSelecionado(deposito: Deposito | null) {
		this.depositoSelecionado = deposito
	}

	@Mutation
	atualizaDeposito(deposito: Deposito) {
		this.depositoSelecionado = deposito
		const depositoIndex = this.depositosCarregados.findIndex(
			d => d.id === deposito?.id,
		)

		depositoIndex === -1
			? this.depositosCarregados.push(deposito)
			: this.depositosCarregados.splice(depositoIndex, 1, deposito)
	}

	get deposito() {
		return this.depositoSelecionado
	}

	get depositos() {
		return this.depositosCarregados
	}
}

export const DepositoModule = getModule(DepositoStore)
