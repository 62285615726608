










































import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import { DetalhamentoDaReservaDoProduto, EstoqueProjection } from '@/models'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import { FindEstoqueUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import {
	Vue,
	Component,
	Watch,
} from 'vue-property-decorator'
import { DataOptions } from 'vuetify'

@Component({
	components: {
		DataTableDeCrud,
	},
})

export default class DialogoDeDetalhamento extends Vue {
	idLoja: string | null = null
	
	estoque: EstoqueProjection | null = null

	mostra = false

	buscando = false

	cancelTokenEstoque: CancelTokenSource | null = null
	errors: string[] = []

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalDeElementos = -1

	listagemDeDetalhes: DetalhamentoDaReservaDoProduto[] = []

	findEstoqueUseCase = new FindEstoqueUseCase()

	header = [
		{text: 'Data',value: 'data', sortable: false},
		{text: 'Identificador da Venda',value: 'identificador', sortable: false},
		{text: 'Cliente/Loja',value: 'nome', sortable: false},
		{text: 'Modalidade de Venda',value: 'modalidade', sortable: false},
	]

	mostrar(estoque, lojaId) {
		this.idLoja = lojaId		
		this.estoque = estoque
		this.buscaDeDetalhes()
		this.mostra = true
	}

	@Watch('paginacao')
	async buscaDeDetalhes(){
		try {
			this.buscando = true
			
			const params = {
				estoqueId: this.estoque?.id,
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
			}			

			if (this.cancelTokenEstoque) this.cancelTokenEstoque.cancel()
			this.cancelTokenEstoque = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelTokenEstoque.token,
			}

			const page = await this.findEstoqueUseCase.findDetalhamento(params, axiosConfig)

			this.listagemDeDetalhes = page.content
			
			this.listagemDeDetalhes = this.listagemDeDetalhes.map(item => {
				return {
					...item,
					data: dateToPtBrFormat(item.data),
				};
			});
			this.totalDeElementos = page.totalElements

		} catch (error) {
			this.errors = error.response.data
		} finally {
			this.buscando = false
		}
	}

	irPara(Identificador: string, isPedido: boolean) {
		const filtro = {
			busca: '',
			lojaId: '',
			datas: [null, null],
			horas: [null, null],
			cliente: null,
			pdvId: '',
			numeroDaNota: '',
			serieFiscal: '',
			exibeVendasComErros: false,
			ambientes: ['Homologação', 'Produção'],
			identificador: Identificador,
			identificadorExterno: '',
			vendasComOrcamento: false,
			vendasComPagamentoCancelado: false,
			origem: null,
		}
		localStorage.setItem('FILTRO_DE_VENDAS', JSON.stringify(filtro));
		if(isPedido){
			window.open(`/pedidos`, '_blank');
		}else{
			window.open(`/vendas`, '_blank');
		}
	}
}
